import * as React from "react";

export default function usePageWidth() {
  const [isMobile, setisMobile] = React.useState(true);

  React.useEffect(() => {
    const handlePageWidth = () => {
      if (typeof window !== "undefined") {
        setisMobile(document.body.offsetWidth < 1024);
      }
    };

    if (typeof window !== "undefined") {
      window.addEventListener("resize", handlePageWidth);
    }

    handlePageWidth();
    return () => {
      window.removeEventListener("resize", handlePageWidth);
    };
  }, []);

  return { isMobile };
}
