import styled from "styled-components";
import { AnimatePresence, AnimateSharedLayout, motion } from "framer-motion";
import * as React from "react";
import { useTheme } from "styled-components";
import { H1 } from "../components/copy";
import { FixedWidth, Header, Main } from "../components/layout/Semantic";
import PageWrapper from "../components/layouts/Page";
import Content from "../content/team.json";
import LocationPin from "../assets/images/svg/location_pin.inline.svg";
import usePageWidth from "../hooks/usePageWidth";

const Team = styled(motion.ul)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  list-style: none;

  @media screen and (min-width: 960px) {
    flex-direction: row;
    flex-wrap: wrap;
    list-style: none;
    align-items: unset;
    justify-content: center;
  }
`;

const TeamItem = styled(motion.li)`
  cursor: pointer;
  background-color: #fff;
  padding: 12px 18px;

  border: 10px solid
    ${({ theme, isOpen }) => (isOpen ? theme.COLORS.vermilion : "transparent")};
  width: calc(100% - 32px);
  margin: 16px 0;

  h2 {
    font-size: ${({ theme, isOpen }) =>
      isOpen ? theme.FONTS.sizes.xlarge : theme.FONTS.sizes.large};
    margin: 0 0 6px;
  }

  h3 {
    font-size: ${({ theme, isOpen }) =>
      isOpen ? theme.FONTS.sizes.large : theme.FONTS.sizes.default};
    margin: 6px 0;
  }

  @media screen and (min-width: 960px) {
    width: ${({ isOpen }) => (isOpen ? "calc(66%)" : "calc(192px)")};
    height: ${({ isOpen }) => (isOpen ? "auto" : "calc(192px)")};
    padding: ${({ isOpen }) => (isOpen ? "40px 65px" : "24px")};
    margin: 16px;
  }
`;

// markup
const OurTalentPage = (props) => {
  const theme = useTheme();
  const { isMobile } = usePageWidth();

  const [openViews, setOpenViews] = React.useState(
    Array(Object.keys(Content.team).length)
      .fill(false)
      .map((_, i) => i === 0)
  );

  const handleOpen = (i) => {
    setOpenViews(
      Array(openViews.length)
        .fill(false)
        .map((_, j) => i === j)
    );
  };

  return (
    <PageWrapper {...props} name="team">
      <Header
        style={{ alignItems: "center", backgroundColor: theme.COLORS.snow }}
      >
        <H1 style={{ color: theme.COLORS.casino }}>{Content.title}</H1>
      </Header>
      <Main style={{ backgroundColor: theme.COLORS.snow }}>
        <FixedWidth>
          <AnimateSharedLayout>
            <Team layout>
              {Object.keys(Content.team).map((member, i) => {
                return (
                  <TeamItem
                    isOpen={openViews[i]}
                    layout
                    onClick={() => handleOpen(i)}
                  >
                    <motion.div
                      layout
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      <motion.h2 layout>{Content.team[member].name}</motion.h2>
                      <motion.h3 layout>{Content.team[member].title}</motion.h3>
                    </motion.div>
                    <AnimatePresence>
                      {openViews[i] && (
                        <motion.div
                          style={{ position: "relative" }}
                          layout
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          exit={{ opacity: 0 }}
                        >
                          <motion.span
                            style={{
                              position: isMobile ? undefined : "absolute",
                              top: -100,
                              right: 0,
                              margin: "4px 0",
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                            layout
                          >
                            <LocationPin />
                            {Content.team[member].location}
                          </motion.span>
                          <motion.p layout>
                            {Content.team[member].description}
                          </motion.p>
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </TeamItem>
                );
              })}
            </Team>
          </AnimateSharedLayout>
        </FixedWidth>
      </Main>
    </PageWrapper>
  );
};

export default OurTalentPage;
