import { motion } from "framer-motion";
import styled from "styled-components";

export const Header = styled(motion.header)`
  display: flex;
  flex-direction: column;
  position: relative;
  padding-top: 135px;
`;

export const Main = styled(motion.main)`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const Section = styled(Main).attrs({
  as: "section",
})`
  flex-direction: column;
  width: ${({ fixed }) => (fixed ? "clamp(320px, 100%, 1280px)" : "auto")};
  background-color: ${({ theme, bgColor }) =>
    bgColor ? theme.COLORS[bgColor] : "transparent"};
`;

export const Article = styled(Main).attrs({
  as: "article",
})``;

export const ArticleColumn = styled(motion.div)`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const ArticleWithColumns = styled(Article)`
  display: flex;
  flex-direction: column;

  ${ArticleColumn} {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex-shrink: 0;
  }

  @media screen and (min-width: 960px) {
    flex-direction: row;
    flex-wrap: nowrap;

    ${ArticleColumn} {
      flex-grow: 0;
    }
  }
`;

export const FixedWidth = styled(motion.div)`
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 1279px) {
    width: 1280px;
    margin: 0 auto;
  }
`;

export const Grid = styled(motion.div)`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
`;
