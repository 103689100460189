import React from "react";
import styled from "styled-components";
import { motion, useAnimation } from "framer-motion";

const NavigationProgressCoverExiting = styled(motion.div)`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 100%;
  background-color: ${({ theme }) => theme.COLORS.vermilion};
  z-index: 9999;
`;

const NavigationProgressCoverEntering = styled(NavigationProgressCoverExiting)`
  right: 0;
`;

const ContentLoader = styled(motion.div)`
  display: flex;
  flex-direction: column;
`;

const PageWrapper = ({
  transitionStatus,
  entry,
  exit,
  mount,
  children,
  name,
}) => {
  const animator = useAnimation();

  React.useEffect(() => {
    if (transitionStatus === "exiting") {
      animator.start({
        right: ["100%", "0%"],
        transition: { duration: 1 },
      });
    } else if (transitionStatus === "entering") {
      animator.start({
        left: ["0%", "100%"],
        transition: { duration: 1 },
      });
    }
  }, [animator, transitionStatus]);
  return (
    <>
      <ContentLoader
        variants={{ hidden: { opacity: 1 }, visible: { opacity: 1 } }}
        animate={mount ? "visibile" : "hidden"}
      >
        {children}
      </ContentLoader>
      {transitionStatus === "exiting" && (
        <NavigationProgressCoverExiting animate={animator} />
      )}
      {transitionStatus === "entering" && (
        <NavigationProgressCoverEntering animate={animator} />
      )}
    </>
  );
};
export default PageWrapper;
