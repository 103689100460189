import * as React from "react";
import styled from "styled-components";

const Wrapper = styled.div``;

const ScrollVisibilityObserver = ({
  thresholds = [1.0],
  margin = 0,
  parent = null,
  children = () => null,
  onScrollStateChange = () => {},
  as = "div",
}) => {
  const scrollRef = React.useRef();
  const [observerState, setObserverState] = React.useState({
    isInView: false,
  });

  const scrollProcessor = React.useCallback(
    (entries) => {
      const entry = entries[0];
      const newState = {
        isInView: false,
      };
      if (entry.isIntersecting) {
        newState.isInView = true;
      }
      setObserverState(newState);
      if (typeof onScrollStateChange === "function")
        onScrollStateChange(newState);
    },
    [onScrollStateChange]
  );

  const [observer, setObserver] = React.useState(
    typeof window !== "undefined"
      ? new IntersectionObserver(scrollProcessor, {
          rootMargin: margin + "px",
          root: !parent ? null : parent,
          threshold: thresholds,
        })
      : null
  );

  React.useEffect(() => {
    if (!observer) {
      setObserver(
        new IntersectionObserver(scrollProcessor, {
          rootMargin: margin + "px",
          root: !parent ? null : parent,
          threshold: thresholds,
        })
      );
    }
  }, [observer, thresholds, margin, parent, scrollProcessor]);

  React.useEffect(() => {
    if (scrollRef.current && observer) {
      observer.observe(scrollRef.current);
    }
  }, [scrollRef, observer]);

  return (
    <>
      <Wrapper as={as} ref={scrollRef} />
      {children(observerState)}
    </>
  );
};

export default ScrollVisibilityObserver;
