import * as React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import ScrollVisibilityObserver from "../utils/ScrollVisibilityObserver";

export const H1 = styled(motion.h1)`
  font-size: ${({ theme }) => theme.FONTS.sizes.xlarge};
  @media screen and (min-width: 960px) {
    font-size: ${({ theme }) => theme.FONTS.sizes.xxlarge};
  }
  color: ${({ theme }) => theme.COLORS.brandVermilion};
`;

export const H2 = styled(motion.h2)`
  font-size: ${({ theme }) => theme.FONTS.sizes.xlarge};
  color: ${({ theme, darkMode }) =>
    darkMode ? theme.COLORS.snow : theme.COLORS.darkest};
`;

export const H3 = styled(motion.h3)`
  font-size: ${({ theme }) => theme.FONTS.sizes.xlarge};
  color: ${({ theme, color }) =>
    color ? theme.COLORS[color] : theme.COLORS.darkest};
  background: ${({ theme, patternColor }) => {
    const color = patternColor
      ? theme.COLORS[patternColor]
      : theme.COLORS.rainyday;
    return `
  repeating-linear-gradient(60deg, ${color}, ${color} 1px, transparent 1px, transparent 7px)`;
  }};
  padding: 24px;
`;

export const H4 = styled(motion.h4)`
  font-size: ${({ theme }) => theme.FONTS.sizes.large};
  font-weight: 700;
  color: ${({ theme, color }) =>
    color ? theme.COLORS[color] : theme.COLORS.darkest};
`;

export const P = styled(motion.p)`
  font-size: ${({ theme }) => theme.FONTS.sizes.large};
  color: ${({ theme, darkMode }) =>
    darkMode ? theme.COLORS.snow : theme.COLORS.abyss};
  line-height: 2;
`;

const OL = styled(motion.ol)`
  display: block;
  padding: 0;
  margin: 0;
`;

const UL = styled(motion.ul)`
  display: flex;
  padding: 0;
  margin: 0;
  list-style: none;
  flex-direction: column;

  & li {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 48px;
    margin: 42px 0;

    &::after {
      position: absolute;
      content: "";
      display: block;
      width: 28px;
      height: 28px;
      left: 0;
      background-color: ${({ theme, listColor }) => theme.COLORS[listColor]};
    }
  }

  @media screen and (min-width: 959px) {
    ${({ horizontal }) =>
      horizontal ? `flex-direction: row; flex-wrap: wrap` : undefined};

    li {
      ${({ horizontal }) =>
        horizontal
          ? `flex-basis: calc(50% - 32px); margin: 0 16px;`
          : undefined};
    }
  }
`;

const DL = styled(motion.dl)`
  display: block;
  padding: 0;
  margin: 0;
`;

const Li = styled(motion.li)`
  color: ${({ theme, textColor }) =>
    textColor ? theme.COLORS[textColor] : theme.COLORS.darkest};
`;

const listRenderers = {
  ul: UL,
  ol: OL,
  dl: DL,
};

const getListRenderer = (listType) => listRenderers[listType];

export const List = ({
  listType = "ul",
  items,
  listColor = "marine",
  textColor = "darkest",
  horizontal = false,
}) => {
  const List = getListRenderer(listType);

  return (
    <List listColor={listColor} horizontal={horizontal}>
      {items.map((item, i) => {
        return (
          <ScrollVisibilityObserver key={i} thresholds={[1]} margin={-100}>
            {({ isInView }) => {
              return (
                <Li
                  initial={{ x: -20, opacity: 0 }}
                  animate={isInView ? "show" : "hide"}
                  variants={{
                    hide: { x: -20, opacity: 0 },
                    show: { x: 0, opacity: 1 },
                  }}
                  textColor={textColor}
                >
                  {item}
                </Li>
              );
            }}
          </ScrollVisibilityObserver>
        );
      })}
    </List>
  );
};
